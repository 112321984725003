* {
  box-sizing: border-box;
}

body {
  margin: 0px 5vw;
  font-family: 'Roboto', sans-serif;
}

header {
  width: 100%;
  position:fixed;
  top: 0;
  left: 0;
  display:flex;
  align-items: center;
  background-color: white;
}

a {
  text-decoration: none;
  color: black;
}

ul {
  display: flex;
  list-style: none;
  gap: 5px;
}

p {
  font-size: 1.5rem;
  text-align: justify;
}

#logo {
  width: 10vw;
  object-fit: cover;
}

footer {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
}

footer img {
  width: 100px;
}

footer #logo {
  margin-left: auto;
  margin-right: auto;
}

nav {
  display: flex;
  margin-left: auto;
}

.navbar__desktop {
  display: none;
}

.menu__nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu__nav li {
  font-size: 2rem;
  padding: 1rem;
}

.australia {
  display: block;
  margin: auto;
  width: 20vw;
}

.grid {
  margin: 5vh;
  display: grid;
  gap: 5vw;
}

.grid h2 {
  text-align: center;
}

.grid img {
  display: block;
  margin: auto;
  width: 20vw;
  object-fit: cover;
}

.container {
  background-color: white;
  display:flex;
  flex-direction: column;
}

.container p:last-child {
  margin-bottom: 0;
}

.container ul {
  display: flex;
  flex-direction: column;
}

.container ul li {
  font-size: 1.5rem;
}

.dropdown {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.img__AS {
  border: 2px solid #F08D91;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.img__middle {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35vw;
  object-fit: cover;
}

footer img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 15vw;
  object-fit: cover;
}

.spacer {
  height: 5vh;
}


@media screen and (min-width: 768px) {

  body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    background-color: #f1f1f1;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
  }

  .wrapper {
    margin: 0 auto;
    padding: 0 20px;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }

  header a {
    display: block;
  }

  header ul a {
    display: block;
    height: 100%;
  }

  header img {
    height: 5vw;
    border-radius: 50%;
    overflow: hidden;
  }

  .align {
    line-height: 6.5vh;
  }

  .spacer {
    height: 10vh;
  }

  h1,h2,h3 {
    text-align: center;
    margin-bottom: 5vh;
    
  }
  
  #menu {
    display: none;
  }

  #logo {
    width: 5vw;
  }

  nav {
    display: flex;
    align-items: center;
    margin:0;
    height: 100%;
  }

  .navbar__desktop {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .navbar__desktop li {
    margin: 0 10px;
    opacity: 0;
    transform: translateY(-10px);
    animation: fadeInDown 0.6s forwards;
  }

  .navbar__desktop li a {
    display: block;
    padding: 0 10px;
    text-decoration: none;
    color: #000;
  }

  .navbar__desktop li a:hover {
    color: #333;
  }

  .navbar__desktop li:nth-child(1) {
    animation-delay: 0.2s;
  }

  .navbar__desktop li:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .navbar__desktop li:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  .navbar__desktop li:nth-child(4) {
    animation-delay: 0.8s;
  }
  
  .navbar__desktop li:nth-child(5) {
    animation-delay: 1s;
  }

  .navbar__desktop li:nth-child(6) {
    animation-delay: 1.2s;
  }

  .container {
    display: block;
  }

  .img__AS {
    margin-right: 1vw;
    width: 25%;
    height: 25%;
    float: left;
  }

  .float__right {
    border: 2px solid #F08D91;
    border-radius: 5px;
    width: 15vw;
    margin-left: 2rem;
    object-fit: cover;
    float: right;
  }

  .float__left {
    border: 2px solid #F08D91;
    border-radius: 5px;
    width: 15vw;
    margin-right: 2rem;
    object-fit: cover;
    float: left;
  }

  p {
    font-size: 1.5rem;
  }

  ul {
    height: 100%;
    gap:0px;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  header li {
    height: 100%;
    background-color: #8FBCB7;
    font-size: 1.5rem;
    padding: 1rem;
    text-align: center;
    border-style: solid;
    border-width: 1px;
  }

  .container li {
    list-style: inside;
    padding: 0.75rem 0;
  }

  .dropdown {
    position: relative;
    line-height: 6.5vh;
  }

  .absolute {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0,10vh);
    line-height: normal;
  }

  .one {
    width: 45vw;
    grid-column: 1 / 2;
    grid-row: 1;
  }

  .two {
    width: 45vw;
    grid-column: 2 / 2;
    grid-row: 1;
  }

  .three {
    width: 45vw;
    grid-column: 1 / 2;
    grid-row: 2;
  }

  .four {
    width: 45vw;
    grid-column: 2 / 2;
    grid-row: 2;
  }

  .five {
    width: 45vw;
    grid-column: 1 / 2;
    grid-row: 3;
  }

  .six {
    width: 45vw;
    grid-column: 2 / 2;
    grid-row: 3;
  }

  .seven {
    width: 45vw;
    grid-column: 1 / 2;
    grid-row: 4;
  }

  .eight {
    width: 45vw;
    grid-column: 2 / 2;
    grid-row: 4;
  }

  .grid__img {
    margin-left: 10vw;
  }

  @keyframes fadeInDown {
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
}